<template>
<v-app id="inspire">
    <div class="px-4">
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <v-container class="container--fluid grid-list-md text-center">
            <h1>Account Limit</h1>
        </v-container>
        <v-card elevation="2" outlined shaped tile class="pa-1">
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout wrap>
                            <v-flex xs12 sm6>
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Search"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color="success" @click.stop="showAccountLimitForm('CREATED')">Create Account Limit</v-btn>
                                <account-limit-form ref="form" :selected-item="selectedAccountLimit" :is-show="showFormDialog" :form-type="accountLimitFormType" v-on:close-dialog="closeDialog" v-on:save-account-limit="saveAccountLimit" />
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
        </v-card>

        <v-data-table 
            :hide-default-header="true" 
            :headers="headers" 
            :items="displayAccountLimits" 
            :search="search"
            :loading="loading" 
            :server-items-length="totalAccountLimits.length" >
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr :colspan="headers.length">
                        <th v-for="header in headers" :key="header.text">
                            <span class="d-flex justify-center">{{header.text}}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                        <td> <span class="d-flex justify-center"> {{ item.accountLimitId }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.name }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.dateCreated }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.lastUpdated }} </span></td>
                        <td>
                            <span class="d-flex justify-center">
                                <v-btn fab dark x-small color="green" @click.stop="showAccountLimitForm('UPDATE', item)">
                                    <v-icon dark medium>mdi-pencil</v-icon>
                                </v-btn>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Found no results.
                </v-alert>
            </template>
        </v-data-table>
    </div>
</v-app>
</template>

<script>
import API from '@/api/API.js'
import Loading from '@/views/components/Loading.vue'
import AccountLimitForm from '@/views/components/AccountLimitForm.vue'
import {
    mapGetters
} from 'vuex';

export default {
    name: 'Account Limits',
    components: {
        Loading,
        AccountLimitForm
    },
    data() {
        return {
            //filter/search variables
            search: '',
            filterQry: {
                qryName: "",
            },
            headers: [],
            displayAccountLimits: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            accountLimitFormType: 'CREATE',
            selectedAccountLimit: {},
            paymentMethods: [],
            totalAccountLimits: 0,
            details: {
                title: '',
                item: {}
            },
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false
        }
    },
    computed: {
        //get role attributes
        ...mapGetters([
            'attributes',
            'user'
        ]),
    },
    async mounted() {
        console.log('mounted')
        this.setHeaders()
        this.queryData()
    },
    methods: {
        async queryData() {
            this.loading = true
            try {
                const accountLimitListResponse = await API.getAccountLimits()
                console.log(accountLimitListResponse)
                if (!accountLimitListResponse || accountLimitListResponse.error) {
                    //error getting data
                    console.log(`${accountLimitListResponse.error}`)
                } else {
                    this.totalAccountLimits = accountLimitListResponse.length
                    this.displayAccountLimits = accountLimitListResponse
                }
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        },
        setHeaders() {
            var headers = [{
                    text: 'Account Limit Code',
                    value: 'code'
                },
                {
                    text: 'Account Limit Name',
                    value: 'name'
                },
                {
                    text: 'Date Created',
                    value: 'dateCreated'
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false
                }
            ]
            this.headers = headers
        },
        showAccountLimitForm(what, item = {}) {
            this.accountLimitFormType = what
            this.selectedAccountLimit = item
            this.showFormDialog = true
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.$router.go(0);
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        async saveAccountLimit(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                if (value.formType === 'CREATED') {
                    this.loadingMessage = `Creating new account limit <strong><i>${value.accountLimitName}</i></strong>`
                    setTimeout(async () => {
                        const createResponse = await API.createAccountLimits(value)
                        this.loadingFinished = true
                        if (!createResponse || createResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = createResponse.error
                        } else {
                            this.loadingMessage = `Successfully created account limit <strong><i>${value.accountLimitName}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.form.resetForm()
                        }
                    }, 2000);
                } else if (value.formType === 'UPDATE') {
                    this.loadingMessage = `Updating account limit <strong><i>${value.accountLimitName}</i></strong>`
                    setTimeout(async () => {
                        const updateResponse = {} //await API.updateMerchant(value)
                        this.loadingFinished = true
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = updateResponse.error
                        } else {
                            this.loadingMessage = `Successfully updated account limit <strong><i>${value.accountLimitName}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.form.resetForm()
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command"
                    this.loadingFinished = true
                }
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        search(event, type) {
            this.query[type] = event
        }
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%;
    margin-bottom: 50px;
}
</style>
